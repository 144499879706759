@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #33FFFF;
}

.Tabel_Button_view {
  background-color: #16120b;
  border-radius: 4px;
  border: 1px solid #58472d;
  color: #33FFFF;
}

.ant-table-thead {
  @media only screen and (max-width: 959px) {
    // display: none;
  }
}

.recent_Offer_tabel_row {
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #303030;
}

.ant-upload-select {
  width: 100%;
}
.ant-select-arrow {
  color: white !important;
}
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.ant-picker-ok > button {
  color: white !important;
}

.ant-empty-normal .ant-empty-description{
  color: white!important;
}

.ant-empty-normal .ant-empty-image{
  opacity: 0.4;
}

.tooltip{
    color: #909090!important;
}

.manual-btn{
  background: unset;
  border-color: #33FFFF;
  color: #33FFFF;

  &:hover{
    background-color: #33ffff30!important;
    color: #33FFFF!important;
  }
}

.approve-modal{
top: 25%;
padding-bottom: 0px;

  .ant-modal-header{
    background-color: unset!important;
  .ant-modal-title{
    line-height: normal;
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

  }
  }
  input {
    color: white !important;
}
.ant-modal-close{
  color: white;
}
.ant-btn-default{
  color: white!important;
}
}